
import { loadBlockData } from '~/components/globals/blocks/list'
import getCraftPages from '~/queries/craft-pages.gql'
import getVideos from "~/queries/get-video.gql"
import pageMixin from '@bkwld/cloak/mixins/page'
import { isDark } from '@bkwld/light-or-dark'

assetBlocks = [
	'blocks_copyAndCtaOverMediaAsset_BlockType'
	'blocks_carouselMarquee_BlockType'
	'blocks_heroMarquee_BlockType'
	'blocks_heroSlider_BlockType'
	'blocks_multiSlideMarquee_BlockType'
]

export default
	name: 'Tower'

	mixins: [ pageMixin ]

	# Provide page hash needed for cueing up featured video in video-carousel component
	provide: -> { @pageHash }

	# Get Tower data
	asyncData: ({ app, store, params, $axios, route, query, req }) ->

		# Don't query for these paths
		return app.$notFound() if params.tower in [ '__webpack_hmr' ]

		# Get data
		[ page ] = await app.$craft.getEntries
			query: getCraftPages
			variables:
				section: 'towers'
				type: 'towers'
				uri: params.tower || '__home__'
		return app.$notFound() unless page

		# Set data
		store.commit 'notification-bar/setData', page.notificationBar?[0]
		store.commit 'tower/setLightMarquee', do =>
			return true if params.tower in ['404', '500'] # Error pages are light
			return false unless topBlock = page.blocks[0]
			if page.pushDown then return !isDark(page.blocks?[0]?.backgroundColor || '#fff')
			switch topBlock.__typename
				when 'blocks_simpleMarquee_BlockType' then true
				when 'blocks_spacer_BlockType' then true
				# when 'blocks_wrapper_BlockType' then !isDark topBlock.backgroundColor || '#fff'
				# when 'blocks_standardMarquee_BlockType' then isDark topBlock.textColor || '#000'
				when 'blocks_wrapper_BlockType' then !isDark topBlock.backgroundColor
				when 'blocks_standardMarquee_BlockType' then isDark topBlock.textColor
				else false

		store.commit 'tower/setDarkLastBlock', do ->
			return if params.tower in ['404', '500']
			lastBlock = page.blocks[page.blocks.length - 1]
			if !lastBlock then return
			switch lastBlock.__typename
				# when 'blocks_wrapper_BlockType' then isDark lastBlock.backgroundColor || '#fff'
				when 'blocks_wrapper_BlockType' then isDark lastBlock.backgroundColor
				else false

		pageHash = route.hash

		return { page, pageHash }

	watch:
		firstWrapperBackgroundColor: (color) ->
			@$store.commit 'layout/setHeaderBackgroundColor', color

		isAssetMarquee: (isAssetMarquee) ->
			@$store.commit 'layout/setIsAssetMarquee', isAssetMarquee

	computed:
		classes: -> ['push-down' if @page.pushDown]

		firstWrapperBackgroundColor: ->
			@page.blocks?[0]?.backgroundColor

		style: ->
			return unless @firstWrapperBackgroundColor
			backgroundColor: @firstWrapperBackgroundColor

		isAssetMarquee: -> @checkIfIsAssetBlockType @page.blocks

	mounted: ->
		@$store.commit 'layout/setHeaderBackgroundColor', @firstWrapperBackgroundColor
		@$store.commit 'layout/setIsAssetMarquee', @isAssetMarquee
		@$store.commit 'layout/setUserAgent', navigator.userAgent

	methods:
		checkIfIsAssetBlockType: (blocks) ->
			blockType = blocks?[0]?.__typename
			if blockType == 'blocks_wrapper_BlockType' then return @checkIfIsAssetBlockType blocks[0].blocks
			if blockType == 'blocks_spacer_BlockType' then return @checkIfIsAssetBlockType blocks.slice 1, blocks.length
			if assetBlocks.includes blockType then return true

